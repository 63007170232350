import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import Spinner from '@shoreag/base/Spinner';
import Table from '@shoreag/base/Table';
import formatDateTimePretty from '@shoreag/helpers/format-date-time-pretty';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { required } from '@shoreag/validations';
import Paginator from '@shoreag/base/Paginator';
import { useMutation, useQuery } from 'react-apollo';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import ButtonGroups from '../ButtonGroups';
import Icon from '../Icon';
import KeyValuePairs from '../KeyValuePairs';
import NotFoundPage from '../../routes/default/404';
import PipelineExecutionResultPills from '../PipelineExecutionResultPills';
import PipelineTypeLabel from '../PipelineTypeLabel';
import Route from '../Route';
import TableIcon from '../TableIcon';
import TableWrapper from '../TableWrapper';
import ToolbarButton from '../ToolbarButton';
import config from '../../config.json';
import formatUuid from '../../utilities/format-uuid';
import isPermitted from '../../utilities/is-permitted';
import parseUuid from '../../utilities/parse-uuid';
import pipelineDetailsPageQuery from '../../graphql/queries/pipeline-details-page.gql';
import deactivatedPipelineMutation from '../../graphql/mutations/activate-deactivate-pipeline.gql';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import PipelineInformation from './PipelineInformation';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';
import useSnackbar from '../../utilities/use-snackbar';
import PipelineEditHandler from '../PipelineEditHandler';

const PipelineDetailsPage = ({ location, pipelineId, navigate }) => {
  const { user } = useContext(AuthContext);
  const { code, version } = parseUuid(pipelineId);
  const [pipelineData, setPipelineData] = useState(null);
  const [pipelineDeactived, setPipelineDeactived] = useState(false);
  const [activeDeactiveLoading, setActiveDeactiveLoading] = useState(false);
  const [pipelineExecutions, setPipelineExecutions] = useState({
    executionLoading: false,
    executions: [],
  });
  const [pageCriteria, setPageCriteria] = useState({
    getExecutions: true,
    getPipeline: true,
    offset: 0,
    pageSize: 10,
    sortByDesc: 0,
  });
  const [deactivatedPipeline] = useMutation(deactivatedPipelineMutation);
  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  const steps = get(pipelineData, 'pipeline.steps', []);

  const { data, loading } = useQuery(pipelineDetailsPageQuery, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    variables: {
      id: pipelineId,
      pipelineCode: code,
      searchBody: JSON.stringify({
        ...pageCriteria,
        steps,
      }),
    },
  });

  useEffect(() => {
    if (!loading) {
      setPipelineData(data);
      const isDiactivated = get(data, 'pipeline.deactivated', false);
      const executions = get(data, 'pipeline.executions', []);
      setPipelineExecutions({ executionLoading: false, executions });
      setPipelineDeactived(isDiactivated);
    }
  }, [data, loading]);

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const hasExecutePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.X,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const pageTitle = get(pipelineData, 'pipeline.displayName', '');
  const isPublished = get(pipelineData, 'pipeline.isPublished', false);
  const hasSteps = !!get(pipelineData, 'pipeline.steps', []).length;
  const executions = get(pipelineExecutions, 'executions', []);
  const executionLoading = get(pipelineExecutions, 'executionLoading', false);
  const hasExecutions = !!executions.length;
  const executionOffset = get(data, 'pipeline.executionOffset', '');
  const { offset, pageSize } = pageCriteria;

  const allowExecution =
    hasExecutePermission &&
    ((hasExecutions && !pipelineDeactived) || !hasExecutions) &&
    hasSteps;

  let statusMessage;
  if (activeDeactiveLoading) {
    statusMessage = 'Loading..';
  } else {
    statusMessage = pipelineDeactived ? 'Disabled' : 'Active';
  }

  return (
    <Route
      header={{
        icon: 'workflow',
        leftContainer: pipelineData && (
          <FinalForm
            initialValues={{
              deactivated: !pipelineDeactived,
              versionNumber: version,
            }}
            onSubmit={noop}
            render={({ handleSubmit }) => (
              <Box
                as="form"
                fontSize={2}
                ml={4}
                onSubmit={handleSubmit}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 2,
                  ml: 4,
                }}
              >
                <Field
                  component={Dropdown}
                  label={`${config.nomenclature.Pipeline} Version`}
                  labelSx={{ display: 'none' }}
                  name="versionNumber"
                  onChange={(version) => {
                    const param = formatUuid('pipeline', code, version);
                    const options = { replace: true };
                    setPipelineData(null);
                    setPageCriteria((prev) => ({
                      ...prev,
                      getPipeline: true,
                      offset: 0,
                      sortByDesc: 0,
                    }));
                    navigate(
                      `/${config.nomenclature.pipelines}/${param}`,
                      options
                    );
                  }}
                  options={get(pipelineData, 'allPipelineVersion', [])
                    .map((value) => ({
                      label: `Version ${value}`,
                      value,
                    }))
                    .reverse()}
                  validate={required}
                  wrapperSx={{ width: '200px' }}
                />
                <Field
                  component={ToggleSwitch}
                  disabled={!hasExecutions || activeDeactiveLoading || loading}
                  errorSx={{
                    ml: 4,
                    mt: 0,
                  }}
                  label={statusMessage}
                  labelSx={{
                    color: pipelineDeactived ? 'grays.3' : 'accent',
                    ml: 0,
                    mr: 4,
                    order: 'unset',
                    textTransform: 'unset',
                  }}
                  name="deactivated"
                  onClick={async () => {
                    setActiveDeactiveLoading(true);
                    const isDeactivatedRequested = !pipelineDeactived;
                    try {
                      const result = await deactivatedPipeline({
                        variables: {
                          deactivated: !pipelineDeactived,
                          id: pipelineId,
                        },
                      });
                      const { deactivated, id } = get(
                        result,
                        'data.activateDeactivatePipeline',
                        null
                      );
                      if (id) {
                        setPipelineDeactived(deactivated);
                        setSnack(
                          <Box sx={{ textAlign: 'center' }}>
                            Workflow <b>{pageTitle}</b> is{' '}
                            {isDeactivatedRequested ? (
                              <Box as="span" sx={{ color: 'error' }}>
                                deactivated.
                              </Box>
                            ) : (
                              <Box as="span" sx={{ color: 'success' }}>
                                activated.
                              </Box>
                            )}
                          </Box>
                        );
                      }
                    } catch (e) {
                      setErrorSnack(
                        <Box sx={{ textAlign: 'center' }}>
                          Workflow <b>{pageTitle}</b>{' '}
                          {isDeactivatedRequested
                            ? 'deactivation'
                            : 'activation'}{' '}
                          failed, try again later.
                        </Box>
                      );
                    } finally {
                      setActiveDeactiveLoading(false);
                    }
                  }}
                  type="checkbox"
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: 4,
                    mt: 0,
                    width: 'auto',
                  }}
                />
              </Box>
            )}
          />
        ),
        rightContainer: pipelineData && (
          <ButtonGroups>
            {hasWritePermission && (
              <>
                {isPublished ? (
                  <PipelineEditHandler pipelineId={pipelineId} />
                ) : (
                  <ToolbarButton
                    icon="edit"
                    label="Edit"
                    link={`/${config.nomenclature.pipelines}/${pipelineId}/edit`}
                  />
                )}
              </>
            )}
            {allowExecution && (
              <ToolbarButton
                active
                icon="clock"
                label="Execute"
                link={`/${config.nomenclature.pipelines}/${pipelineId}/schedule`}
              />
            )}
          </ButtonGroups>
        ),
        title: pageTitle,
        type: config.nomenclature.Pipeline,
      }}
      isPrivate
    >
      {loading && !data && <Spinner />}
      {!loading && !data && <NotFoundPage />}
      {data && pipelineData && (
        <>
          <Card>
            <h2>Overview</h2>
            <KeyValuePairs
              /* eslint-disable sort-keys */
              pairs={Object.entries({
                type: (
                  <PipelineTypeLabel
                    pipelineType={pipelineData?.pipeline?.pipelineType}
                  />
                ),
                description: pipelineData?.pipeline?.description,
                lastUpdated: formatDateTimePretty(
                  pipelineData?.pipeline?.lastUpdated
                ),
                executedBy: pipelineData?.pipeline?.executedBy,
                partner: pipelineData?.pipeline?.partner,
                autoTrigger: pipelineData?.pipeline?.autoTrigger || '',
                copiedFrom: pipelineData?.pipeline?.copiedFrom,
                tags: pipelineData?.pipeline?.tags || [],
              }).map(enhanceKeyValuePairEntries)}
              /* eslint-enable sort-keys */
            />
          </Card>
          <Card mt={5}>
            <Box as="h2" mt="-0.75rem">
              Executions
            </Box>
            <TableWrapper>
              {executionLoading ? (
                <Spinner py="md" />
              ) : (
                <Table
                  header={[
                    '#',
                    'Started On',
                    'Dataset',
                    'Total Records',
                    'Results',
                    '',
                  ]}
                  rows={executions.map((execution) => {
                    const {
                      executionDate,
                      results,
                      executionNumber,
                    } = execution;
                    const inputDetails = JSON.parse(
                      results[0]?.inputDetails || '{}'
                    );
                    return [
                      execution.executionNumber,
                      formatDateTimeConcise(executionDate),
                      get(
                        inputDetails.dataset || inputDetails.sourceDataset,
                        'name',
                        'Not Applicable'
                      ),
                      results[0]?.outputs
                        ? get(
                            JSON.parse(results[0].outputs),
                            'total_records',
                            0
                          )
                        : 0,
                      <PipelineExecutionResultPills
                        results={results}
                        steps={pipelineData?.pipeline?.steps}
                      />,
                      <ButtonGroups sx={{ justifyContent: 'flex-end' }}>
                        <Button
                          link={`${location.pathname}/execution/${executionNumber}`}
                          simple
                        >
                          <TableIcon svg="view" title="View" />
                        </Button>
                        {!pipelineDeactived && (
                          <Button
                            link={`${location.pathname}/schedule?repeat=${executionNumber}`}
                            simple
                          >
                            <TableIcon svg="repeat" title="Execute Again" />
                          </Button>
                        )}
                      </ButtonGroups>,
                    ];
                  })}
                />
              )}
            </TableWrapper>
            <TooltipBox id="tooltip" />
          </Card>

          <Paginator
            leftButtonContent={
              <Icon height="0.75rem" svg="left-chevron" width="0.5rem" />
            }
            offset={offset}
            onNextClick={() => {
              setPipelineExecutions((prev) => ({
                ...prev,
                executionLoading: true,
              }));
              setPageCriteria((prev) => ({
                ...prev,
                offset: offset + pageSize,
                sortByDesc: executionOffset,
              }));
            }}
            onPreviousClick={() => {
              setPipelineExecutions((prev) => ({
                ...prev,
                executionLoading: true,
              }));
              setPageCriteria((prev) => ({
                ...prev,
                offset: offset < 0 ? 0 : offset - pageSize,
                sortByDesc: `${Number(executionOffset || 1) + pageSize * 2}`,
              }));
            }}
            rightButtonContent={
              <Icon height="0.75rem" svg="right-chevron" width="0.5rem" />
            }
            total={
              executionOffset
                ? Number(executionOffset + pageSize)
                : offset + pageSize
            }
          />
          {!!pipelineData?.pipeline?.steps.length && (
            <>
              <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                {config.nomenclature.Steps}
              </Box>
              <PipelineInformation
                allStepDefinitions={pipelineData?.allStepDefinitions}
                inputs={pipelineData?.pipeline?.inputs}
                steps={pipelineData?.pipeline?.steps}
              />
            </>
          )}
        </>
      )}
    </Route>
  );
};

PipelineDetailsPage.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  navigate: PropTypes.func.isRequired,
  pipelineId: PropTypes.string,
};

PipelineDetailsPage.defaultProps = {
  pipelineId: null,
};

export default PipelineDetailsPage;
