import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { navigate } from 'gatsby';
import Box from '@shoreag/base/Box';
import Modal from '@shoreag/base/Modal';
import ReactGA from 'react-ga';
import stripTypename from '@shoreag/helpers/strip-typename';
import ToolbarButton from '../ToolbarButton';
import config from '../../config.json';
import pipelineEditPageQuery from '../../graphql/queries/pipeline-edit-page.gql';
import parseUuid from '../../utilities/parse-uuid';
import { PIPELINE_INPUT_TYPES } from '../../utilities/constants';
import generateParsePipelineSteps from '../PipelineEditPage/utilities/generate-parse-pipeline-steps';
import useSaveUpdatePipeline from '../PipelineEditPage/lib/useSaveUpdatePipeline';

const PipelineEditHandler = ({ pipelineId }) => {
  const [showError, setShowError] = useState(false);
  const { isSaving, saveUpdatePipeline, savingError } = useSaveUpdatePipeline();

  useEffect(() => {
    if (savingError) {
      setShowError(true);
    }
  }, [savingError]);

  return (
    <>
      <Query
        query={pipelineEditPageQuery}
        variables={{
          id: pipelineId,
        }}
      >
        {({ data, loading }) => {
          return (
            <ToolbarButton
              disabled={loading || isSaving}
              icon="edit"
              label="Edit"
              loading={isSaving}
              onClick={async () => {
                const {
                  code: pipelineCode,
                  version: pipelineVersionNo,
                } = parseUuid(data.pipeline?.copiedFrom?.id);
                const isPublished = get(data, 'pipeline.isPublished', false);
                const pipelineValues = stripTypename({
                  ...data.pipeline,
                  autoTrigger: data.pipeline.autoTrigger || undefined,
                  copyPipelineCode: pipelineCode,
                  copyPipelineVersionId: pipelineVersionNo,
                  inputs: [
                    ...data.pipeline.inputs,
                    ...(data.pipeline.inputs.length
                      ? []
                      : [
                          {
                            name: 'dataset',
                            type: PIPELINE_INPUT_TYPES.DATASET,
                          },
                        ]),
                  ],
                  partner: data.pipeline.partner
                    ? data.pipeline.partner.id
                    : undefined,
                  steps: data.pipeline.steps.map(
                    generateParsePipelineSteps(
                      data.allStepDefinitions,
                      data.allValueMapperDefinitions
                    )
                  ),
                });

                const { newPipelineId } = await saveUpdatePipeline({
                  data,
                  pipelineId,
                  values: {
                    ...pipelineValues,
                    isNewVersion: isPublished,
                    isPublished: false,
                  },
                });
                if (newPipelineId) {
                  ReactGA.event({
                    action: `${config.nomenclature.Pipeline} Edited`,
                    category: config.nomenclature.Pipelines,
                    label: newPipelineId,
                  });

                  navigate(
                    `/${config.nomenclature.pipelines}/${newPipelineId}/edit`
                  );
                }
              }}
              type="button"
            />
          );
        }}
      </Query>
      {showError && (
        <Modal
          closeText="Close"
          isOpen={showError}
          onClose={() => setShowError(false)}
          title="Error"
        >
          <Box sx={{ color: 'error', textAlign: 'center' }}>
            Something went wrong! please try again.
            {savingError ? <Box as="p">{savingError}</Box> : ''}
          </Box>
        </Modal>
      )}
    </>
  );
};

PipelineEditHandler.propTypes = {
  pipelineId: PropTypes.string.isRequired,
};

export default PipelineEditHandler;
